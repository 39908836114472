import styles from "./Logo.module.css";

const Logo = (props) => {
    let r_marker = props.marker
    let r_serv_marker = props.serv_marker
    let r_trs = props.trs;
    let logo = (props.fields.LOGO === undefined) ? '' : props.fields.LOGO[0].url
    let link = (props.fields.LINK === undefined) ? '' : props.fields.LINK
    if (r_serv_marker.length > 0 && r_marker !== r_serv_marker) {
        link = link.replace('XXX', r_serv_marker).replace('YYY', r_trs)
    } else {
        link = link.replace('XXX', r_marker).replace('YYY', r_trs)
    }
    if (r_trs === 0){
        link = link.replace('&trs=0','')
    }
    return (
        <a href={link} className={styles.logo} rel="noreferrer" target="_blank">
            <img src={logo} alt="" title="" width={90} height={30}/>
        </a>
    )
}

export default Logo