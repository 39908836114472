import "./Reviews.css"
import styles from "./Reviews.module.css"
import Container from "../../../Ui/Container/Container"
import Wrap from "../../../Ui/Wrap/Wrap"
import Title from "../../../Ui/Title/Title"
import ardown from "../../../images/arrow-down.svg"
import {ReactSVG} from "react-svg";
import React from "react";
import Loader from "../../../Ui/loader/Loader";
import Review from "./Review/Review";
import {Swiper, SwiperSlide} from "swiper/react";

class Reviews extends React.Component {
    state = {
        isLoading: false,
        items: [],
        marker: 0,
        serv_marker: '',
        rekls: [],
    }

    get_trs(rekl) {
        let rekls = this.state.rekls
        if (rekls[rekl] === undefined) {
            return -1
        } else {
            return rekls[rekl]
        }
    }

    async componentDidMount() {
        let api_server = this.props.api_server
        let marker = this.props.marker
        let serv_marker = this.props.serv_marker
        let rekls = this.props.rekls
        let type = 'reviews'
        let update = 0
        const obj = this

        const xhr = new XMLHttpRequest();
        xhr.open('GET', api_server + 'key=11eb6dd2f9dcf57686be6f609d853f9c&type=' + type + '&update=' + update, true);
        xhr.send();
        this.setState({
            isLoading: true,
            marker: marker,
            serv_marker:serv_marker,
            rekls: rekls,
        })
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) {
                return false
            }
            if (xhr.status !== 200) {
                console.log(xhr.status + ': ' + xhr.statusText)
            } else {
                let items = JSON.parse(xhr.responseText).records

                let items_filtered = []

                items.map(
                    function (item, index) {
                        let trs = obj.get_trs(item.fields.REKL_ID)
                        if (trs >= 0) {
                            let new_item = item
                            new_item.trs = trs
                            items_filtered.push(new_item)
                        }
                        return null
                    }
                )

                this.setState({
                    items: items_filtered,
                    isLoading: false,
                })
            }
        }
    }


    render() {
        const title = 'Читайте отзывы и выбирайте лучший сервис'
        if (this.state.isLoading) {
            return (
                <section className={styles.reviews} id="Reviews">
                    <Container>
                        <Wrap>
                            <div className={styles.reviews__ardown}>
                                <ReactSVG src={ardown}/>
                            </div>
                            <div className={styles.reviews__br}/>
                            <Title>
                                {title}
                            </Title>
                            <div className={styles.reviews__slider}>
                                <Loader/>
                            </div>
                        </Wrap>
                    </Container>
                </section>
            )
        } else {
            let obj = this
            return (
                <section className={styles.reviews} id="Reviews">
                    <Container>
                        <Wrap>
                            <div className={styles.reviews__ardown}>
                                <ReactSVG src={ardown}/>
                            </div>
                            <div className={styles.reviews__br}/>
                            <Title>
                                {title}
                            </Title>
                            <div className={styles.reviews__slider}>
                                <div className={styles.reviews__prev_btn} id={"reviews-slider-prev"}/>
                                <div className={styles.reviews__next_btn} id={"reviews-slider-next"}/>
                                <Swiper
                                    direction={'vertical'}
                                    spaceBetween={0}
                                    slidesPerView={3}
                                    loop={true}
                                    navigation={{
                                        prevEl: "#reviews-slider-prev",
                                        nextEl: "#reviews-slider-next",
                                    }}
                                >
                                    {
                                        this.state.items.map(
                                            function (item, index) {

                                                return (
                                                    <SwiperSlide key={item.id}>
                                                        <Review
                                                            itemId={item.id}
                                                            fields={item.fields}
                                                            marker={obj.state.marker}
                                                            serv_marker={obj.state.serv_marker}
                                                            trs={item.trs}
                                                        />
                                                    </SwiperSlide>
                                                )
                                            }
                                        )

                                    }
                                </Swiper>
                            </div>
                        </Wrap>
                    </Container>
                </section>
            )
        }
    }
}

export default Reviews