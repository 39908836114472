import animation from '../../images/loader.svg'
import {ReactSVG} from "react-svg";
function Loader() {
    return (
        <div className="loader">
           <ReactSVG src={animation}/>
        </div>
    );
}

export default Loader
