import React from 'react'
import styles from './About.module.css'
import Container from "../../../Ui/Container/Container"
import Wrap from "../../../Ui/Wrap/Wrap"
import woman from '../../../images/about/girl.svg'
import man from '../../../images/about/man.svg'


const About = () => {
    return (
        <section className={styles.about} id="About">
            <Container>
                <Wrap>
                    <div className={styles.about__text}>
                        <div className={styles.about__heading}>
                            <svg width="587" height="146" viewBox="0 0 587 146" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M174.971 45.5001L186.151 67.0002H163.447L155.019 47.8221H150.547V67.0002H129.907V2.50014H162.415C177.207 2.50014 184.603 10.0395 184.603 25.1181C184.603 35.4381 181.392 42.2321 174.971 45.5001ZM155.277 19.0121H150.547V31.9121H155.277C157.799 31.9121 159.605 31.6541 160.695 31.1381C161.841 30.6221 162.415 29.4468 162.415 27.6121V23.3121C162.415 21.4775 161.841 20.3021 160.695 19.7861C159.605 19.2701 157.799 19.0121 155.277 19.0121Z"
                                    fill="#1B1D29"/>
                                <path
                                    d="M191.851 2.50014H212.491V50.9181H219.801C222.438 50.9181 224.216 50.5741 225.133 49.8861C226.108 49.1981 226.595 47.7075 226.595 45.4141V2.50014H247.235V38.7061C247.235 44.2101 246.862 48.8541 246.117 52.6381C245.429 56.4221 244.053 59.5181 241.989 61.9261C239.982 64.2768 237.173 65.9682 233.561 67.0002C229.949 68.0322 225.276 68.5481 219.543 68.5481C213.81 68.5481 209.137 68.0322 205.525 67.0002C201.913 65.9682 199.075 64.2768 197.011 61.9261C195.004 59.5755 193.628 56.5081 192.883 52.7241C192.195 48.8828 191.851 44.2101 191.851 38.7061V2.50014Z"
                                    fill="#1B1D29"/>
                                <path
                                    d="M253.387 65.8821L256.311 48.8541C262.618 50.4021 268.323 51.1761 273.425 51.1761C276.005 51.1761 278.27 51.1475 280.219 51.0901C282.226 50.9755 284.003 50.8035 285.551 50.5741V45.4141L276.263 44.6401C272.078 44.2388 268.581 43.5508 265.771 42.5761C262.962 41.6015 260.697 40.2828 258.977 38.6201C257.315 36.9001 256.111 34.8648 255.365 32.5141C254.677 30.1061 254.333 27.2968 254.333 24.0861C254.333 19.6715 254.792 16.0021 255.709 13.0781C256.627 10.0968 258.089 7.71748 260.095 5.94015C262.102 4.10548 264.682 2.81548 267.835 2.07014C271.046 1.32481 274.945 0.952148 279.531 0.952148C288.647 0.952148 296.903 1.81215 304.299 3.53215L301.719 20.0441C295.298 19.0121 290.167 18.4961 286.325 18.4961C282.484 18.4961 279.216 18.6681 276.521 19.0121V24.0861L283.917 24.7741C288.447 25.2328 292.231 26.0068 295.269 27.0961C298.308 28.1281 300.745 29.5041 302.579 31.2241C304.471 32.9441 305.79 34.9795 306.535 37.3301C307.338 39.6808 307.739 42.3755 307.739 45.4141C307.739 49.7715 307.166 53.4408 306.019 56.4221C304.873 59.4035 303.497 61.6681 301.891 63.2161C300.573 64.4775 298.939 65.4808 296.989 66.2262C295.04 66.9142 292.976 67.4301 290.797 67.7741C288.619 68.1181 286.411 68.3188 284.175 68.3762C281.997 68.4908 280.019 68.5481 278.241 68.5481C273.769 68.5481 269.469 68.3188 265.341 67.8601C261.213 67.4015 257.229 66.7421 253.387 65.8821Z"
                                    fill="#1B1D29"/>
                                <path
                                    d="M312.932 65.8821L315.856 48.8541C322.163 50.4021 327.867 51.1761 332.97 51.1761C335.55 51.1761 337.815 51.1475 339.764 51.0901C341.771 50.9755 343.548 50.8035 345.096 50.5741V45.4141L335.808 44.6401C331.623 44.2388 328.125 43.5508 325.316 42.5761C322.507 41.6015 320.242 40.2828 318.522 38.6201C316.859 36.9001 315.655 34.8648 314.91 32.5141C314.222 30.1061 313.878 27.2968 313.878 24.0861C313.878 19.6715 314.337 16.0021 315.254 13.0781C316.171 10.0968 317.634 7.71748 319.64 5.94015C321.647 4.10548 324.227 2.81548 327.38 2.07014C330.591 1.32481 334.49 0.952148 339.076 0.952148C348.192 0.952148 356.448 1.81215 363.844 3.53215L361.264 20.0441C354.843 19.0121 349.712 18.4961 345.87 18.4961C342.029 18.4961 338.761 18.6681 336.066 19.0121V24.0861L343.462 24.7741C347.991 25.2328 351.776 26.0068 354.814 27.0961C357.853 28.1281 360.29 29.5041 362.124 31.2241C364.016 32.9441 365.335 34.9795 366.08 37.3301C366.883 39.6808 367.284 42.3755 367.284 45.4141C367.284 49.7715 366.711 53.4408 365.564 56.4221C364.418 59.4035 363.042 61.6681 361.436 63.2161C360.117 64.4775 358.483 65.4808 356.534 66.2262C354.585 66.9142 352.521 67.4301 350.342 67.7741C348.163 68.1181 345.956 68.3188 343.72 68.3762C341.541 68.4908 339.564 68.5481 337.786 68.5481C333.314 68.5481 329.014 68.3188 324.886 67.8601C320.758 67.4015 316.773 66.7421 312.932 65.8821Z"
                                    fill="#1B1D29"/>
                                <path d="M395.697 67.0002V2.50014H375.057V67.0002H395.697Z" fill="#1B1D29"/>
                                <path
                                    d="M423.763 56.7661H440.791L443.199 67.0002H464.957L448.273 2.50014H416.367L399.683 67.0002H421.441L423.763 56.7661ZM432.879 22.2801L437.007 40.3401H427.547L431.761 22.2801H432.879Z"
                                    fill="#1B1D29"/>
                                <path d="M50.568 79.5001H0V96.0121H14.964V144H35.604V96.0121H50.568V79.5001Z"
                                      fill="#1B1D29"/>
                                <path
                                    d="M101.274 122.5L112.454 144H89.7504L81.3224 124.822H76.8504V144H56.2104V79.5001H88.7184C103.51 79.5001 110.906 87.0395 110.906 102.118C110.906 112.438 107.696 119.232 101.274 122.5ZM81.5804 96.0121H76.8504V108.912H81.5804C84.1031 108.912 85.9091 108.654 86.9984 108.138C88.1451 107.622 88.7184 106.447 88.7184 104.612V100.312C88.7184 98.4775 88.1451 97.3021 86.9984 96.7861C85.9091 96.2701 84.1031 96.0121 81.5804 96.0121Z"
                                    fill="#1B1D29"/>
                                <path
                                    d="M137.419 133.766H154.447L156.855 144H178.613L161.929 79.5001H130.023L113.339 144H135.097L137.419 133.766ZM146.535 99.2801L150.663 117.34H141.203L145.417 99.2801H146.535Z"
                                    fill="#1B1D29"/>
                                <path
                                    d="M208.923 120.436H207.977L199.205 79.5001H177.447L193.443 144H223.543L239.539 79.5001H217.781L208.923 120.436Z"
                                    fill="#1B1D29"/>
                                <path
                                    d="M285.951 104.698H265.311V96.0121H287.499L290.079 79.5001H244.671V144H290.595V127.488H265.311V119.662H285.951V104.698Z"
                                    fill="#1B1D29"/>
                                <path d="M340.625 127.488H319.985V79.5001H299.345V144H340.625V127.488Z"
                                      fill="#1B1D29"/>
                                <path
                                    d="M414.904 125.51L416.452 142.882C412.037 144.659 406.59 145.548 400.112 145.548C393.977 145.548 388.903 144.889 384.89 143.57C380.876 142.194 377.694 140.13 375.344 137.378C372.993 134.626 371.33 131.129 370.356 126.886C369.438 122.643 368.98 117.627 368.98 111.836C368.98 106.217 369.41 101.315 370.27 97.1301C371.187 92.8875 372.792 89.3615 375.086 86.5521C377.379 83.6855 380.475 81.5355 384.374 80.1021C388.33 78.6688 393.318 77.9521 399.338 77.9521C400.656 77.9521 402.118 78.0381 403.724 78.2101C405.386 78.3248 406.992 78.4968 408.54 78.7261C410.145 78.9555 411.636 79.2421 413.012 79.5862C414.445 79.8728 415.592 80.2168 416.452 80.6181L413.356 96.4421C408.769 95.4675 404.67 94.9801 401.058 94.9801C397.331 94.9801 394.751 95.3241 393.318 96.0121C391.884 96.7001 391.168 98.0761 391.168 100.14V127.144C392.486 127.431 393.805 127.66 395.124 127.832C396.5 127.947 397.904 128.004 399.338 128.004C405.071 128.004 410.26 127.173 414.904 125.51Z"
                                    fill="#1B1D29"/>
                                <path d="M464.922 127.488H444.282V79.5001H423.642V144H464.922V127.488Z"
                                      fill="#1B1D29"/>
                                <path
                                    d="M469.041 79.5001H489.681V127.918H496.991C499.629 127.918 501.406 127.574 502.323 126.886C503.298 126.198 503.785 124.707 503.785 122.414V79.5001H524.425V115.706C524.425 121.21 524.053 125.854 523.307 129.638C522.619 133.422 521.243 136.518 519.179 138.926C517.173 141.277 514.363 142.968 510.751 144C507.139 145.032 502.467 145.548 496.733 145.548C491 145.548 486.327 145.032 482.715 144C479.103 142.968 476.265 141.277 474.201 138.926C472.195 136.575 470.819 133.508 470.073 129.724C469.385 125.883 469.041 121.21 469.041 115.706V79.5001Z"
                                    fill="#1B1D29"/>
                                <path
                                    d="M570.224 144H533.158V79.5001H566.612C572.804 79.5001 577.218 80.7041 579.856 83.1122C582.493 85.5201 583.812 89.0175 583.812 93.6041C583.812 98.2482 582.98 101.831 581.318 104.354C579.655 106.819 577.39 108.453 574.524 109.256V109.858C582.837 111.291 586.994 116.967 586.994 126.886C586.994 132.046 585.618 136.203 582.866 139.356C580.171 142.452 575.957 144 570.224 144ZM561.882 118.286H553.798V128.864H561.71C564.634 128.864 566.096 127.087 566.096 123.532C566.096 120.035 564.691 118.286 561.882 118.286ZM560.334 93.6041H553.798V103.236H560.162C562.799 103.236 564.118 101.631 564.118 98.4201C564.118 95.2095 562.856 93.6041 560.334 93.6041Z"
                                    fill="#1B1D29"/>
                            </svg>
                        </div>

                        <div className={styles.about__desc}>
                            Подборка лучших путешествий по России
                        </div>
                    </div>
                </Wrap>
            </Container>
            {
                (window.innerWidth > 991) && (<><div className={styles.about__woman}><img src={woman} alt="" title="" width={249} height={241}/></div><div className={styles.about__man}><img src={man} alt="" title="" width={470} height={296}/></div></>)
            }
        </section>
    )
}

export default About