import styles from "./CashbackLogo.module.css";
import {ReactSVG} from "react-svg";

const Logo = (props) => {
    let r_marker = props.marker;
    let r_serv_marker = props.serv_marker
    let r_trs = props.trs;
    let logo = props.fields.LOGO[0].url
    let description = props.fields.DESCRIPTION
    let link = props.fields.LINK
    if (r_serv_marker.length > 0 && r_marker !== r_serv_marker) {
        link = link.replace('XXX', r_serv_marker).replace('YYY', r_trs)
    } else {
        link = link.replace('XXX', r_marker).replace('YYY', r_trs)
    }
    if (r_trs === 0) {
        link = link.replace('&trs=0', '')
    }
    return (
        <div className={styles.cashback_logo}>
            <div className={styles.cashback_logo__logo}>
                <ReactSVG src={logo}/>
            </div>
            <div className={styles.cashback_logo__desc}>
                {description}
            </div>
            <div className={styles.cashback_logo__link}>
                <a href={link} target="blank" rel="noreferrer">
                    Перейти
                </a>
            </div>
        </div>
    )
}

export default Logo