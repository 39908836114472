import styles from "./Card.module.css";
import StarRatings from 'react-star-ratings';
import LazyLoad from 'react-lazyload';


const Card = (props) => {
    let r_marker = props.marker
    let r_serv_marker = props.serv_marker
    let r_trs = props.trs
    let rekl = (props.fields.REKL === undefined) ? '' : props.fields.REKL
    let link = (props.fields.LINK === undefined) ? '' : props.fields.LINK
    let cashback = (props.fields.CASHBACK === undefined) ? false : props.fields.CASHBACK
    let image = (props.fields.IMAGE === undefined) ? '' : props.fields.IMAGE[0].url
    let destination = (props.fields.DESTINATION === undefined) ? '' : props.fields.DESTINATION
    let description = (props.fields.DESCRIPTION === undefined) ? '' : props.fields.DESCRIPTION
    let description1 = (props.fields.DESCRIPTION1 === undefined) ? '' : props.fields.DESCRIPTION1
    let description2 = (props.fields.DESCRIPTION2 === undefined) ? '' : props.fields.DESCRIPTION2
    let price = (props.fields.PRICE === undefined) ? '' : props.fields.PRICE
    let reviews = (props.fields.REVIEWS === undefined) ? '' : props.fields.REVIEWS
    let stars = (props.fields.STARS === undefined) ? '' : props.fields.STARS
    if (stars === 'NONE') {
        stars = 0
    }
    if (r_serv_marker.length > 0 && r_marker !== r_serv_marker) {
        link = link.replace('XXX', r_serv_marker).replace('YYY', r_trs)
    } else {
        link = link.replace('XXX', r_marker).replace('YYY', r_trs)
    }
    if (r_trs === 0) {
        link = link.replace('&trs=0', '')
    }

    switch (props.type) {
        case 'ideas':
            return (
                <div key={props.itemId + props.type} className={styles.card}>
                    <div className={styles.card__labels}>
                        {
                            (rekl) ? (<div className={styles.card__label_r}>{rekl}</div>) : ''
                        }
                        {
                            (cashback) ? (<div className={styles.card__label_sale}>Кешбэк 20%</div>) : ''
                        }
                    </div>
                    <div className={styles.card__image}>
                        <LazyLoad height={120}>
                            <img src={image} alt="" title="" width={286} height={119}/>
                        </LazyLoad>
                    </div>
                    <div className={styles.card__heading_1}>
                        {
                            destination
                        }
                    </div>
                    <div className={styles.card__heading_2}>
                        {
                            description1
                        }
                    </div>
                    <div className={styles.card__description_1}>
                        {
                            description2
                        }
                    </div>
                    <div className={styles.card__price}>
                        {
                            price
                        }
                    </div>
                    <a href={link} target="_blank" rel="noreferrer" className={styles.card__link}>
                        Перейти
                    </a>
                </div>
            )
        case 'hotels':
            return (
                <div key={props.itemId + props.type} className={styles.card}>
                    <div className={styles.card__labels}>
                        {
                            (rekl) ? (<div className={styles.card__label_r}>{rekl}</div>) : ''
                        }
                        {
                            (cashback) ? (<div className={styles.card__label_sale}>Кешбэк 20%</div>) : ''
                        }
                    </div>
                    <div className={styles.card__image}>
                        <LazyLoad height={120}>
                            <img src={image} alt="" title="" width={286} height={119}/>
                        </LazyLoad>
                    </div>
                    <div className={styles.card__stars}>

                        {
                            [1, 2, 3, 4, 5].map(function (item, index) {
                                if (index < stars) {
                                    return (
                                        <span key={index}></span>
                                    )
                                } else {
                                    return null
                                }
                            })
                        }
                    </div>
                    <div className={styles.card__heading_3}>
                        {
                            destination
                        }
                    </div>
                    <div className={styles.card__description_2}>
                        {
                            description
                        }
                    </div>
                    <div className={styles.card__price}>
                        {
                            price
                        }
                    </div>

                    <a href={link} target="_blank" rel="noreferrer" className={styles.card__link}>
                        Перейти
                    </a>
                </div>
            )
        case 'excursions':
            return (
                <div key={props.itemId + props.type} className={styles.card}>
                    <div className={styles.card__labels}>
                        {
                            (rekl) ? (<div className={styles.card__label_r}>{rekl}</div>) : ''
                        }
                        {
                            (cashback) ? (<div className={styles.card__label_sale}>Кешбэк 20%</div>) : ''
                        }
                    </div>
                    <div className={styles.card__image}>
                        <LazyLoad height={120}>
                            <img src={image} alt="" title="" width={286} height={119}/>
                        </LazyLoad>
                    </div>
                    <div className={styles.card__heading_4}>
                        {
                            destination
                        }
                    </div>
                    <div className={styles.card__description_2}>
                        {
                            description
                        }
                    </div>
                    <div className={styles.card__reviews}>
                        <StarRatings
                            rating={parseFloat(reviews.replace(',', '.'))}
                            numberOfStars={5}
                            starDimension="16px"
                            starSpacing="2px"
                            starRatedColor="#5956F3"
                            starEmptyColor="#E7ECFE"
                            svgIconPath="M7 0L9.26297 3.88528L13.6574 4.83688L10.6616 8.18972L11.1145 12.6631L7 10.85L2.8855 12.6631L3.33843 8.18972L0.342604 4.83688L4.73703 3.88528L7 0Z"
                            svgIconViewBox="0 0 14 13"
                        />
                    </div>

                    <div className={styles.card__price}>
                        {
                            price
                        }
                    </div>

                    <a href={link} target="_blank" rel="noreferrer" className={styles.card__link}>
                        Перейти
                    </a>
                </div>
            )
        case 'transport':
            return (
                <div key={props.itemId + props.type} className={styles.card}>
                    <div className={styles.card__labels}>
                        {
                            (rekl) ? (<div className={styles.card__label_r}
                                           style={{borderRadius: '8px'}}>{rekl}</div>) : ''
                        }
                    </div>
                    <div className={styles.card__heading_5}>
                        {
                            description1
                        }
                    </div>
                    <div className={styles.card__image} style={{marginTop: '10px'}}>
                        <LazyLoad height={120}>
                            <img src={image} alt="" title="" width={286} height={119}/>
                        </LazyLoad>
                    </div>
                    <div className={styles.card__description_1}>
                        {
                            description2
                        }
                    </div>
                    <div className={styles.card__price}>
                        {
                            price
                        }
                    </div>
                    <a href={link} target="_blank" rel="noreferrer" className={styles.card__link}>
                        Перейти
                    </a>
                </div>
            )
        default:
            return (
                <div key={props.itemId} className={styles.card}>
                    Unable type
                </div>
            )
    }

}

export default Card