import React from 'react'
import styles from "./Hotels.module.css"
import Container from "../../../Ui/Container/Container";
import Wrap from "../../../Ui/Wrap/Wrap";
import Title from "../../../Ui/Title/Title";
import CardSlider from "../../CardSlider/CardSlider";


class Hotels extends React.Component {
    state = {
        is_empty: false
    }
    constructor(props) {
        super(props)
        this.updateParentData = this.updateParentData.bind(this);
    }

    async componentDidMount() {
        this.setState({is_empty: false})
    }

    updateParentData = (value) => {
        this.setState({is_empty: value})
    }


    render() {
        if (!this.state.is_empty) {
            return (
                <section className={styles.hotels} id="Hotels">
                    <Container>
                        <Wrap>
                            <Title>
                                Топ предложений по <strong>отелям</strong>
                            </Title>
                            <CardSlider
                                type="hotels"
                                marker={this.props.marker}
                                serv_marker={this.props.serv_marker}
                                rekls={this.props.rekls}
                                api_server={this.props.api_server}
                                updateParentData={this.updateParentData}
                            />
                        </Wrap>
                    </Container>
                </section>
            )
        } else {
            return null
        }
    }
}


export default Hotels