import React from 'react'
import styles from "./Tours.module.css"
import Card from "../../Card/Card";
import Container from "../../../Ui/Container/Container";
import Wrap from "../../../Ui/Wrap/Wrap";
import Loader from "../../../Ui/loader/Loader";


class Tours extends React.Component {
    state = {
        more_mode: false,
        isLoading: false,
        items: [],
        marker: 0,
        serv_marker: '',
        rekls: [],
    }

    get_trs(rekl) {
        let rekls = this.state.rekls
        if (rekls[rekl] === undefined) {
            return -1
        } else {
            return rekls[rekl]
        }
    }

    async componentDidMount() {
        let api_server = this.props.api_server
        let marker = this.props.marker
        let serv_marker = this.props.serv_marker
        let rekls = this.props.rekls
        let type = 'ideas'
        let update = 0
        let obj = this

        const xhr = new XMLHttpRequest();
        xhr.open('GET', api_server + 'key=11eb6dd2f9dcf57686be6f609d853f9c&type=' + type + '&update=' + update, true);
        xhr.send();
        this.setState({
            isLoading: true,
            marker: marker,
            serv_marker: serv_marker,
            rekls: rekls,
        })
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) {
                return false
            }
            if (xhr.status !== 200) {
                console.log(xhr.status + ': ' + xhr.statusText)
            } else {
                let items = JSON.parse(xhr.responseText).records
                let items_filtered = []

                items.map(
                    function (item, index) {
                        let trs = obj.get_trs(item.fields.REKL_ID)
                        if (trs >= 0) {
                            let new_item = item
                            new_item.trs = trs
                            items_filtered.push(new_item)
                        }
                        return null
                    }
                )

                this.setState({
                    items: items_filtered,
                    isLoading: false,
                })
            }
        }
    }

    handleShowMore = () => {
        let more_mode = !this.state.more_mode
        this.setState({
            more_mode
        })
    }

    btn1 = () => {
        return (
            <div className={styles.travels__more_btn} onClick={this.handleShowMore}>
                Показать больше
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L6 6L11 1" strokeWidth="1.5"/>
                </svg>

            </div>
        )
    }

    btn2 = () => {
        return (
            <div className={styles.travels__more_btn} onClick={this.handleShowMore}>
                Скрыть
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 7L6 2L1 7" strokeWidth="1.5"/>
                </svg>
            </div>
        )
    }

    more_wrap = () => {
        if (this.state.items.length > 6) {
            return (
                <div className={styles.travels__more_wrap}>
                    {
                        (!this.state.more_mode) ? this.btn1() : this.btn2()
                    }
                </div>
            )
        } else {
            return null
        }
    }


    render() {
        if (this.state.isLoading) {
            return (
                <section className={styles.travels} id="Tours">
                    <Container>
                        <Wrap>
                            <Loader/>
                        </Wrap>
                    </Container>
                </section>
            )
        } else {
            if (this.state.items.length > 0) {
                let obj = this
                let maxDisplay = (!this.state.more_mode) ? 6 : 100
                return (
                    <section className={styles.travels} id="Tours">
                        <Container>
                            <Wrap>
                                <div className={styles.travels__items}>
                                    {

                                        this.state.items.map(
                                            function (item, index) {
                                                if (index < maxDisplay) {
                                                    return (
                                                        <div key={item.id} className={styles.travels__item}>
                                                            <Card
                                                                itemId={item.id}
                                                                fields={item.fields}
                                                                type="ideas"
                                                                marker={obj.state.marker}
                                                                serv_marker={obj.state.serv_marker}
                                                                trs={item.trs}
                                                            />
                                                        </div>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            }
                                        )

                                    }
                                </div>
                                {
                                    this.more_wrap()
                                }


                            </Wrap>
                        </Container>
                    </section>
                )
            } else {
                return null
            }
        }
    }
}


export default Tours