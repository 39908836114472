import React from 'react'
import {Link} from 'react-scroll'
import styles from "./HeaderNav.module.css"

import {ReactSVG} from 'react-svg'
import iconIdea from '../../../images/idea.svg'
import iconCashback from '../../../images/cashback.svg'
import iconHotels from '../../../images/hotels.svg'
import iconExc from '../../../images/exc.svg'
import iconTransport from '../../../images/transport.svg'
import iconReviews from '../../../images/reviews.svg'

class HeaderNav extends React.Component {


    render() {
        let offset = -100

        return (
            <nav className={styles.nav}>
                <ul>
                    <li >
                        <Link activeClass={styles.nav__linkActive} className={styles.nav__link} to="Tours" spy={true}
                              smooth={true}
                              duration={500}
                              offset={offset}
                              onClick={this.props.handleMenuClose}
                        >
                            <ReactSVG src={iconIdea} wrapper="span"/>
                            <span>
                                Идеи
                            </span>
                        </Link>
                    </li>
                    { 1 == 2 &&
                    (<li>
                            <Link activeClass={styles.nav__linkActive}
                                  className={styles.nav__link + " " + styles.nav__linkMarked} to="Cashback" spy={true}
                                  smooth={true}
                                  duration={500}
                                  offset={offset}
                                  onClick={this.props.handleMenuClose}
                            >
                                <ReactSVG src={iconCashback} wrapper="span"/>
                                <span>
                                Кешбэк 20%
                            </span>
                            </Link>
                        </li>)
                    }
                    <li>
                        <Link activeClass={styles.nav__linkActive}
                              className={styles.nav__link} to="Hotels" spy={true}
                              smooth={true}
                              duration={500}
                              offset={offset}
                              onClick={this.props.handleMenuClose}
                        >
                            <ReactSVG src={iconHotels} wrapper="span"/>
                            <span>
                                Отели
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link activeClass={styles.nav__linkActive}
                              className={styles.nav__link} to="Excursions" spy={true}
                              smooth={true}
                              duration={500}
                              offset={offset}
                              onClick={this.props.handleMenuClose}
                        >
                            <ReactSVG src={iconExc} wrapper="span"/>
                            <span>
                                Экскурсии
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link activeClass={styles.nav__linkActive}
                              className={styles.nav__link} to="Transport" spy={true}
                              smooth={true}
                              duration={500}
                              offset={offset}
                              onClick={this.props.handleMenuClose}
                        >
                            <ReactSVG src={iconTransport} wrapper="span"/>
                            <span>
                                Транспорт
                            </span>
                        </Link>
                    </li>
                    <li>
                        <Link activeClass={styles.nav__linkActive}
                              className={styles.nav__link} to="Reviews" spy={true}
                              smooth={true}
                              duration={500}
                              offset={offset}
                              onClick={this.props.handleMenuClose}
                        >
                            <ReactSVG src={iconReviews} wrapper="span"/>
                            <span>
                                Отзывы
                            </span>
                        </Link>
                    </li>
                </ul>
            </nav>
        )
    }
}

export default HeaderNav