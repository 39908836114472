import React from 'react'
import styles from "./Logos.module.css"
import Container from "../../../Ui/Container/Container";
import Wrap from "../../../Ui/Wrap/Wrap";

import Logo from "./Logo/Logo";
import Loader from "../../../Ui/loader/Loader";


class Logos extends React.Component {
    state = {
        type: 0,
        items: [],
        isLoading: false,
        marker: 0,
        serv_marker: '',
        rekls: [],
    }

    get_trs(rekl) {
        let rekls = this.state.rekls
        if (rekls[rekl] === undefined) {
            return -1
        } else {
            return rekls[rekl]
        }
    }

    async componentDidMount() {
        let api_server = this.props.api_server
        let marker = this.props.marker
        let serv_marker = this.props.serv_marker
        let rekls = this.props.rekls
        let type = this.props.type
        let update = 0
        let obj = this

        const xhr = new XMLHttpRequest();
        xhr.open('GET', api_server + 'key=11eb6dd2f9dcf57686be6f609d853f9c&type=partners&update=' + update, true);
        xhr.send();
        this.setState({
            isLoading: true,
            type: type,
            marker: marker,
            serv_marker: serv_marker,
            rekls: rekls
        })
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) {
                return false
            }
            if (xhr.status !== 200) {
                console.log(xhr.status + ': ' + xhr.statusText)
            } else {
                let items = JSON.parse(xhr.responseText).records
                let items_filtered = []

                items.map(
                    function (item, index) {
                        let trs = obj.get_trs(item.fields.REKL_ID)
                        if (type === item.fields.CATEGORY && trs >= 0) {
                            let new_item = item
                            new_item.trs = trs
                            items_filtered.push(new_item)
                        }
                        return null
                    }
                )

                this.setState({
                    items: items_filtered,
                    isLoading: false,
                })
            }
        }
    }


    render() {


        if (this.state.isLoading) {
            let type = this.state.type
            return (
                <section id={"logos" + type}>
                    <Container>
                        <Wrap>
                            <div className={styles.flexbox}>
                                <div className={styles.logos}>
                                    <div className={styles.logos__title}>
                                        Смотреть все предложения:
                                    </div>
                                    <Loader/>
                                </div>
                            </div>
                        </Wrap>
                    </Container>
                </section>
            )
        } else {
            if (this.state.items.length > 0) {
                let obj = this
                let type = this.state.type
                return (
                    <section id={"logos" + type}>
                        <Container>
                            <Wrap>
                                <div className={styles.flexbox}>
                                    <div className={styles.logos}>
                                        <div className={styles.logos__title}>
                                            Смотреть все предложения:
                                        </div>
                                        <div className={styles.logos__items}>
                                            {
                                                this.state.items.map(
                                                    function (item, index) {
                                                        if (type === item.fields.CATEGORY) {
                                                            return (
                                                                <div key={item.id} className={styles.logos__item}>
                                                                    <Logo
                                                                        itemId={item.id}
                                                                        fields={item.fields}
                                                                        marker={obj.state.marker}
                                                                        serv_marker={obj.state.serv_marker}
                                                                        trs={item.trs}
                                                                    />
                                                                </div>
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    }
                                                )

                                            }
                                        </div>
                                    </div>
                                </div>

                            </Wrap>
                        </Container>
                    </section>
                )
            } else {
                return null
            }
        }
    }
}


export default Logos