import Header from "./components/Header/Header"
import './Ui/Reset/Reset.css'
import './App.css'
import About from "./components/Sections/About/About";
import Logos from "./components/Sections/Logos/Logos";
import Tours from "./components/Sections/Tours/Tours";
import Cashback from "./components/Sections/Cashback/Cashback";
import Avisales from "./components/Sections/Avisales/Avisales";
import Hotels from "./components/Sections/Hotels/Hotels";
import Excursions from "./components/Sections/Excursions/Excursions";
import Transport from "./components/Sections/Transport/Transport";
import Footer from "./components/Footer/Footer";
import React from "react";
import Loader from "./Ui/loader/Loader";
import Reviews from "./components/Sections/Reviews/Reviews";


class App extends React.Component {

    state = {
        isLoading: true,
        rekls: [],
        marker: 0,
        serv_marker: '',
        api_server: 'https://rutravel.club/api/api.php?'
    }

    params() {
        return window.location.search.replace('?', '').split('&').reduce(function (p, e) {
            var a = e.split('=');
            p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
            return p;
        }, {});
    }

    async componentDidMount() {
        let marker = 11501
        let serv_marker = ''
        if (localStorage.getItem('marker') !== null) {
            marker = localStorage.getItem('marker')
        }
        if (localStorage.getItem('serv_marker') !== null) {
            serv_marker = localStorage.getItem('serv_marker')
        }


        var params = this.params()
        if (params['marker'] !== undefined) {
            marker = parseInt(params['marker'])
            serv_marker = params['marker']
        }

        if (serv_marker.length < 1) {
            serv_marker = marker.toString() + '.$29'
        }

        if (serv_marker.indexOf('.$') === -1) {
            serv_marker += '.$29'
        }

        const xhr = new XMLHttpRequest();
        xhr.open('GET', this.state.api_server + 'key=4f893ee24950069dd823e083338a456d&marker=' + marker, true);
        xhr.send();

        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) {

                return false
            }

            if (xhr.status !== 200) {
                console.log(xhr.status + ': ' + xhr.statusText)
            } else {
                let data = JSON.parse(xhr.responseText)
                if (data['error'] !== undefined) {
                    let marker = 11501
                    const xhr2 = new XMLHttpRequest();
                    xhr2.open('GET', this.state.api_server + 'key=4f893ee24950069dd823e083338a456d&marker=' + marker, true);
                    xhr2.send();
                    xhr2.onreadystatechange = () => {
                        if (xhr2.readyState !== 4) {

                            return false
                        }

                        if (xhr2.status !== 200) {
                            console.log(xhr2.status + ': ' + xhr2.statusText)
                        } else {
                            let data = JSON.parse(xhr2.responseText)
                            if (data['error'] !== undefined) {
                                alert('Ошибка маркера')
                            } else {
                                localStorage.setItem('marker', marker)
                                localStorage.setItem('serv_marker', serv_marker)
                                this.setState({
                                    isLoading: false,
                                    marker: marker,
                                    serv_marker: serv_marker,
                                    rekls: data
                                })
                            }
                        }
                    }
                } else {
                    localStorage.setItem('marker', marker)
                    localStorage.setItem('serv_marker', serv_marker)
                    this.setState({
                        isLoading: false,
                        marker: marker,
                        serv_marker: serv_marker,
                        rekls: data
                    })
                }
            }
        }
    }


    render() {
        if (this.state.isLoading) {
            return (
                <div className="App" id="App">
                    <div className="loading-box">
                        <Loader/>
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    <Header/>
                    <About/>
                    <Tours marker={this.state.marker} serv_marker={this.state.serv_marker} rekls={this.state.rekls}
                           api_server={this.state.api_server}/>
                    <Logos type="tours" marker={this.state.marker} serv_marker={this.state.serv_marker}
                           rekls={this.state.rekls}
                           api_server={this.state.api_server}/>
                    { 1==2 && (<Cashback marker={this.state.marker} serv_marker={this.state.serv_marker}
                               rekls={this.state.rekls}
                               api_server={this.state.api_server}/>)
                    }
                    <Avisales marker={this.state.marker} serv_marker={this.state.serv_marker}
                              rekls={this.state.rekls}
                              api_server={this.state.api_server}/>
                    <Hotels marker={this.state.marker} serv_marker={this.state.serv_marker} rekls={this.state.rekls}
                            api_server={this.state.api_server}/>
                    <Logos type="hotels" marker={this.state.marker} serv_marker={this.state.serv_marker}
                           rekls={this.state.rekls}
                           api_server={this.state.api_server}/>
                    <Excursions marker={this.state.marker} serv_marker={this.state.serv_marker}
                                rekls={this.state.rekls}
                                api_server={this.state.api_server}/>
                    <Logos type="excursions" marker={this.state.marker} serv_marker={this.state.serv_marker}
                           rekls={this.state.rekls}
                           api_server={this.state.api_server}/>
                    <Transport marker={this.state.marker} serv_marker={this.state.serv_marker}
                               rekls={this.state.rekls}
                               api_server={this.state.api_server}/>
                    <Logos type="transport" marker={this.state.marker} serv_marker={this.state.serv_marker}
                           rekls={this.state.rekls}
                           api_server={this.state.api_server}/>
                    <Reviews marker={this.state.marker} serv_marker={this.state.serv_marker}
                             rekls={this.state.rekls}
                             api_server={this.state.api_server}/>
                    <Footer marker={this.state.marker} serv_marker={this.state.serv_marker} rekls={this.state.rekls}
                            api_server={this.state.api_server}/>

                </>
            )
        }
    }
}

export default App;