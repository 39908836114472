import styles from './Wrap.module.css'

function Wrap(props) {
    let clasName = styles.wrap
    if (props.size === 'big') clasName = clasName + ' ' + styles.big
    return (
        <div className={clasName}>
            {props.children}
        </div>
    );
}

export default Wrap;