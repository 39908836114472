import React from 'react'
import styles from "./CardSlider.module.css"
import SwiperCore, {Navigation} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import Card from "../Card/Card";
import Loader from "../../Ui/loader/Loader";

SwiperCore.use([Navigation]);


class CardSlider extends React.Component {
    state = {
        type: '',
        isLoading: false,
        items: [],
        marker: 0,
        serv_marker: '',
        rekls: [],
    }

    get_trs(rekl) {
        let rekls = this.state.rekls
        if (rekls[rekl] === undefined) {
            return -1
        } else {
            return rekls[rekl]
        }
    }

    async componentDidMount() {
        let api_server = this.props.api_server
        let marker = this.props.marker
        let serv_marker = this.props.serv_marker
        let rekls = this.props.rekls
        let type = this.props.type
        let update = 0
        let obj = this

        const xhr = new XMLHttpRequest();
        xhr.open('GET', api_server + 'key=11eb6dd2f9dcf57686be6f609d853f9c&type=' + type + '&update=' + update, true);
        xhr.send();
        this.setState({
            isLoading: true,
            type: type,
            marker: marker,
            serv_marker: serv_marker,
            rekls: rekls,
        })
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) {
                return false
            }

            if (xhr.status !== 200) {
                console.log(xhr.status + ': ' + xhr.statusText)
            } else {
                let items = JSON.parse(xhr.responseText).records
                let items_filtered = []

                items.map(
                    function (item, index) {
                        let trs = obj.get_trs(item.fields.REKL_ID)
                        if (trs >= 0) {
                            let new_item = item
                            new_item.trs = trs
                            items_filtered.push(new_item)
                        }
                        return null
                    }
                )
                this.setState({
                    items: items_filtered,
                    isLoading: false,
                })

                if (items_filtered.length > 0) {
                    obj.props.updateParentData(false)
                } else {
                    obj.props.updateParentData(true)
                }
            }
        }

    }


    render() {

        if (this.state.isLoading) {
            return (
                <Loader/>
            )
        } else {
            if (this.state.items.length > 0) {
                let obj = this
                let count = (window.innerWidth > 991) ? 3 : ((window.innerWidth > 660) ? 2 : 1)
                const allowTouchMove = (window.innerWidth > 991) ? false : true
                return (
                    <div className={styles.cardSlider}>
                        <div className={styles.cardSlider__prev_btn} id={obj.state.type + "-slider-prev"}/>
                        <div className={styles.cardSlider__next_btn} id={obj.state.type + "-slider-next"}/>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={count}
                            loop={((this.state.items.length > 2) ? true : false)}
                            allowTouchMove={allowTouchMove}
                            centeredSlides={((this.state.items.length > 2) ? false : true)}
                            navigation={{
                                prevEl: "#" + obj.state.type + "-slider-prev",
                                nextEl: "#" + obj.state.type + "-slider-next",
                            }}
                        >
                            {
                                this.state.items.map(
                                    function (item, index) {
                                        return (
                                            <SwiperSlide key={item.id}>
                                                <div className={styles.cardSlider__slide}>
                                                    <Card
                                                        itemId={item.id}
                                                        fields={item.fields}
                                                        type={obj.state.type}
                                                        marker={obj.state.marker}
                                                        serv_marker={obj.state.serv_marker} trs={item.trs}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        )
                                    }
                                )

                            }
                        </Swiper>

                    </div>


                )
            } else {
                return null
            }
        }
    }
}


export default CardSlider