import styles from "./Review.module.css"

const Review = (props) => {
    let r_marker = props.marker
    let r_serv_marker = props.serv_marker
    let r_trs = props.trs
    let description = (props.fields.DESCRIPTION === undefined) ? '' : props.fields.DESCRIPTION
    let rekl = (props.fields.REKL === undefined) ? '' : props.fields.REKL
    let person = (props.fields.PERSON === undefined) ? '' : props.fields.PERSON
    let link = (props.fields.LINK === undefined) ? '' : props.fields.LINK
    let rating = (props.fields.RATING === undefined) ? '' : props.fields.RATING
    let rating_system = (props.fields.RATING_SYSTEM === undefined) ? '' : props.fields.RATING_SYSTEM

    if (r_serv_marker.length > 0 && r_marker !== r_serv_marker) {
        link = link.replace('XXX', r_serv_marker).replace('YYY', r_trs)
    } else {
        link = link.replace('XXX', r_marker).replace('YYY', r_trs)
    }
    if (r_trs === 0){
        link = link.replace('&trs=0','')
    }

    return (
        <div className={styles.review}>
            <div className={styles.review__rating}>
                <div className={styles.review__rating_label}>
                    {
                        (window.innerWidth > 991) ? ("рейтинг " + rating_system) : ''
                    }
                </div>
                <div className={styles.review__rating_value}>
                    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 0L15.8794 6.66049L23.4127 8.2918L18.277 14.0395L19.0534 21.7082L12 18.6L4.94658 21.7082L5.72303 14.0395L0.587322 8.2918L8.12062 6.66049L12 0Z"
                            fill="#5956F3"/>
                    </svg>
                    {
                        rating
                    }
                    {
                        (window.innerWidth < 992) ? (<small>рейтинг  {rating_system}</small>) : ''
                    }
                </div>
            </div>
            <div className={styles.review__content}>
                <div className={styles.review__header}>
                    <div className={styles.review__rekl}>
                        {
                            rekl
                        }
                    </div>
                    <div className={styles.review__user}>
                        {
                            person
                        }
                    </div>
                </div>
                <div className={styles.review__text}>
                    {
                        description
                    }
                </div>
                <a href={link} className={styles.review__link} target="_blank" rel="noreferrer">
                    Перейти к отзыву
                    <svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.8231 7.99997C26.2232 7.99997 20.6232 5.66665 17.8232 1" strokeWidth="1.2"/>
                        <path d="M31.8231 8.00003C26.2232 8.00003 20.6232 10.3334 17.8232 15" strokeWidth="1.2"/>
                        <path d="M32 8L0 8" strokeWidth="1.2"/>
                    </svg>
                </a>
            </div>
        </div>
    )
}

export default Review