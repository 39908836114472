import React from 'react'
import styles from './Footer.module.css'
import Container from "../../Ui/Container/Container"
import Wrap from "../../Ui/Wrap/Wrap"
import logo from "../../images/logo2.svg"
import {Link} from 'react-scroll'
import FooterNav from "./FooterNav/FooterNav";


const Footer = (props) => {
    return (
        <header className={styles.footer}>
            <Container>
                <Wrap size={"big"}>
                    <div className={styles.flexbox}>
                        <div className={styles.footer__logo}>
                            <img src={logo} alt={""} title={""} width={"254"} height={"62"}/>
                            <Link className={styles.footer__totop} to="About" spy={true}
                                  smooth={true}
                                  duration={500}
                                  offset={-80}
                            >
                                В начало
                                <svg width="9" height="12" viewBox="0 0 9 12" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.414214 5.08579L1.12132 5.79289L3.94975 2.96447L3.94975 11.4497L5.36396 11.4497V2.96447L8.19239 5.79289L8.89949 5.08579L4.65685 0.843146L0.414214 5.08579Z"
                                        fill="white"/>
                                </svg>
                            </Link>
                        </div>
                        <FooterNav marker={props.marker} serv_marker={props.serv_marker} rekls={props.rekls} api_server={props.api_server}/>
                        <div className={styles.footer__copyright}>
                            <div>2021 г. 0+</div>
                            <div>Все права защищены. Услуги лицензированы.</div>
                        </div>
                    </div>
                </Wrap>
            </Container>
        </header>
    )
}

export default Footer