import styles from './Desc.module.css'

function Desc(props) {
    return (
        <div className={styles.desc}>
            {props.children}
        </div>
    );
}

export default Desc