import styles from "./Aviasales.module.css"
import Container from "../../../Ui/Container/Container"
import Wrap from "../../../Ui/Wrap/Wrap"
import Title from "../../../Ui/Title/Title"
import ardown from "../../../images/arrow-down.svg"
import {ReactSVG} from "react-svg";
import React from "react";

class Avisales extends React.Component {
    componentDidMount() {
        let marker = this.props.marker
        let serv_marker = this.props.serv_marker
        let rekls = this.props.rekls
        let trs = this.get_trs(101, rekls)
        if (trs === 0) {
            trs = this.get_trs(100, rekls)
        }
        if (trs >= 0) {
            serv_marker = serv_marker.substring(serv_marker.indexOf('$'))
            serv_marker = serv_marker.replace('$', '')
            let widget_marker = marker + '.rutravelclub' + serv_marker
            console.log(widget_marker)
            window.TP_FORM_SETTINGS = window.TP_FORM_SETTINGS || {};
            window.TP_FORM_SETTINGS["5191229a78d6da2e362324dee3d866e3"] = {
                "handle": "5191229a78d6da2e362324dee3d866e3",
                "widget_name": "Поисковая форма #1",
                "border_radius": "8",
                "additional_marker": null,
                "width": null,
                "show_logo": true,
                "show_hotels": false,
                "form_type": "avia",
                "locale": "ru",
                "currency": "rub",
                "sizes": "default",
                "search_target": "_blank",
                "active_tab": "avia",
                "search_host": "www.aviasales.ru/search",
                "hotels_host": "search.hotellook.com",
                "hotel": "",
                "hotel_alt": "",
                "avia_alt": "",
                "retargeting": true,
                "trip_class": "economy",
                "depart_date": null,
                "return_date": null,
                "check_in_date": null,
                "check_out_date": null,
                "no_track": false,
                "powered_by": false,
                "source_id": trs,
                "id": 249267,
                "marker": widget_marker,
                "origin": {
                    "name": ""
                },
                "destination": {
                    "name": ""
                },
                "color_scheme": {
                    "name": "custom",
                    "icons": "icons_blue",
                    "background": "#5346EC",
                    "color": "#ffffff",
                    "border_color": "#c1c1c1",
                    "button": "#FA3D71",
                    "button_text_color": "#ffffff",
                    "input_border": "#ffffff"
                },
                "hotels_type": "hotellook_host",
                "best_offer": {
                    "locale": "ru",
                    "currency": "rub",
                    "marker": 225374,
                    "search_host": "www.aviasales.ru/search",
                    "offers_switch": false,
                    "api_url": "//www.travelpayouts.com/minimal_prices/offers.json",
                    "routes": []
                },
                "hotel_logo_host": null,
                "search_logo_host": "www.aviasales.ru",
                "hotel_marker_format": null,
                "hotelscombined_marker": null,
                "sources": [
                    {
                        "id": 17363,
                        "name": "vk.com"
                    }
                ],
                "responsive": true,
                "height": 194
            };
            const script = document.createElement("script");
            script.src = "//www.travelpayouts.com/widgets/5191229a78d6da2e362324dee3d866e3.js?v=2161";
            script.async = true;
            document.getElementById('aviasales-form').appendChild(script)
        }
    }

    get_trs(rekl, rekls) {
        if (rekls[rekl] === undefined) {
            return 0
        } else {
            return rekls[rekl]
        }
    }


    render() {

        return (
            <section className={styles.avisales} id="Avisales">
                <Container>
                    <Wrap>
                        <div className={styles.avisales__ardown}>
                            <ReactSVG src={ardown}/>
                        </div>
                        <div className={styles.avisales__br}/>
                        <Title>
                            Подберите лучшие <strong>билеты</strong>
                        </Title>
                        <div className={styles.avisales__form} id="aviasales-form">

                        </div>
                        <div className={styles.avisales__ardown}>
                            <ReactSVG src={ardown}/>
                        </div>
                    </Wrap>
                </Container>
            </section>
        )
    }
}

export default Avisales