import styles from './Cashback.module.css'
import Container from "../../../Ui/Container/Container";
import Wrap from "../../../Ui/Wrap/Wrap";
import Title from "../../../Ui/Title/Title";
import Desc from "../../../Ui/Desc/Desc";
import cashback from "../../../images/cashback-image.svg"
import cashbackM from "../../../images/cashback-image-m.svg"
import {ReactSVG} from "react-svg";
import React from "react";
import CashbackLogo from "./CashbackLogo/CashbackLogo";
import Loader from "../../../Ui/loader/Loader";

class Cashback extends React.Component {
    state = {
        isLoading: false,
        items: [],
        marker: 0,
        serv_marker: '',
        rekls: [],
    }

    get_trs(rekl) {
        let rekls = this.state.rekls
        if (rekls[rekl] === undefined) {
            return -1
        } else {
            return rekls[rekl]
        }

    }

    async componentDidMount() {
        let api_server = this.props.api_server
        let marker = this.props.marker
        let serv_marker = this.props.serv_marker
        let rekls = this.props.rekls
        let type = 'cashback'
        let update = 0
        let obj = this

        const xhr = new XMLHttpRequest();
        xhr.open('GET', api_server + 'key=11eb6dd2f9dcf57686be6f609d853f9c&type=' + type + '&update=' + update, true);
        xhr.send();
        this.setState({
            isLoading: true,
            marker: marker,
            serv_marker: serv_marker,
            rekls: rekls,
        })
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) {
                return false
            }
            if (xhr.status !== 200) {
                console.log(xhr.status + ': ' + xhr.statusText)
            } else {
                let items = JSON.parse(xhr.responseText).records

                let items_filtered = []

                items.map(
                    function (item, index) {
                        let trs = obj.get_trs(item.fields.REKL_ID)
                        if (trs >= 0) {
                            let new_item = item
                            new_item.trs = trs
                            items_filtered.push(new_item)
                        }
                        return null
                    }
                )
                this.setState({
                    items: items_filtered,
                    isLoading: false,
                })
            }
        }
    }


    render() {
        const title = <React.Fragment>Получите кешбэк <mark>20%</mark> от <strong>Ростуризма</strong> при оплате
            картой <strong>МИР</strong></React.Fragment>
        const desc = <React.Fragment>
            Верните до 20% стоимости тура, проживания в отеле или санаторной путёвки с 1 октября<br/>
            по 24 декабря 2021 г. <a href="https://xn--b1afakdgpzinidi6e.xn--p1ai/" target="blank" rel="noreferrer">Подробные
            условия</a>
        </React.Fragment>
        const box_title = <React.Fragment>Партнёры программы кешбэка:</React.Fragment>

        if (this.state.isLoading) {
            return (
                <section className={styles.cashback} id="Cashback">
                    <Container>
                        <Wrap>
                            <Title>
                                {
                                    title
                                }
                            </Title>
                            <div className={styles.cashback__desc_offset}/>
                            <Desc>
                                {
                                    desc
                                }
                            </Desc>
                            <div className={styles.cashback__image}>
                                <ReactSVG src={(window.innerWidth < 992) ? cashbackM : cashback}/>
                            </div>
                            <div className={styles.cashback_box}>
                                <div className={styles.cashback_box__title}>
                                    {
                                        box_title
                                    }
                                </div>
                                <Loader/>
                            </div>

                        </Wrap>
                    </Container>
                </section>
            )
        } else {
            if (this.state.items.length > 0) {
                let obj = this
                return (
                    <section className={styles.cashback} id="Cashback">
                        <Container>
                            <Wrap>
                                <Title>
                                    {
                                        title
                                    }
                                </Title>
                                <div className={styles.cashback__desc_offset}/>
                                <Desc>
                                    {
                                        desc
                                    }
                                </Desc>
                                <div className={styles.cashback__image}>
                                    <ReactSVG src={(window.innerWidth < 992) ? cashbackM : cashback}/>
                                </div>
                                <div className={styles.cashback_box}>
                                    <div className={styles.cashback_box__title}>
                                        {
                                            box_title
                                        }
                                    </div>
                                    <div className={styles.cashback_box__items}>
                                        {
                                            this.state.items.map(
                                                function (item, index) {
                                                    return (
                                                        <div key={item.id} className={styles.cashback_box__item}>
                                                            <CashbackLogo
                                                                itemId={item.id}
                                                                fields={item.fields}
                                                                marker={obj.state.marker}
                                                                serv_marker={obj.state.serv_marker}
                                                                trs={item.trs}
                                                            />
                                                        </div>
                                                    )

                                                }
                                            )

                                        }
                                    </div>
                                </div>

                            </Wrap>
                        </Container>
                    </section>
                )
            } else {
                return null
            }
        }
    }
}

export default Cashback