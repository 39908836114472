import React from 'react'
import styles from './Header.module.css'
import Container from "../../Ui/Container/Container"
import Wrap from "../../Ui/Wrap/Wrap"
import logo from "../../images/logo.svg"
import HeaderNav from "./HeaderNav/HeaderNav";


class Header extends React.Component {
    state = {
        burgerActive: false,
        mobileMenu: false
    }

    constructor(props) {
        super(props);
        this.handleBurgerClick = this.handleBurgerClick.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
    }

    handleBurgerClick() {
        let burgerActive = !this.state.burgerActive
        let mobileMenu = !this.state.mobileMenu
        this.setState({
                burgerActive,
                mobileMenu
            }
        )
    }

    burgerOpen() {
        return (
            <div className={styles.header__burger} onClick={this.handleBurgerClick}>
                <svg width="16" height="13" viewBox="0 0 16 13" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.125H15M1 6.375H15M1 11.625H15" strokeWidth="1.6"
                          strokeLinecap="round"/>
                </svg>
            </div>
        )
    }

    burgerClose() {
        return (
            <div className={styles.header__burger} onClick={this.handleBurgerClick}>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1L13 13M13 1L1 13" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </div>
        )
    }

    handleMenuClose() {
        this.setState({
                burgerActive: false,
                mobileMenu: false
            }
        )
    }


    render() {
        return (
            <React.Fragment>
                <header className={styles.header}>
                    <Container>
                        <Wrap size={"big"}>
                            <div className={styles.flexbox}>
                                <div className={styles.header__logo}>
                                    <img src={logo} alt={""} title={""} width={"164"} height={"40"}/>
                                </div>
                                {
                                    (window.innerWidth > 991) ? (<HeaderNav handleMenuClose={this.handleMenuClose}/>) : ''
                                }

                                {
                                    (window.innerWidth < 992) ? ((!this.state.burgerActive) ? this.burgerOpen() : this.burgerClose()) : null

                                }
                            </div>
                        </Wrap>
                    </Container>
                </header>
                {
                    (window.innerWidth < 992 && this.state.mobileMenu) ? (
                        <div className={styles.mobile_menu}>
                            <HeaderNav handleMenuClose={this.handleMenuClose}/>
                        </div>
                    ) : ''
                }
            </React.Fragment>
        )
    }
}

export default Header