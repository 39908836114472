import React from 'react'
import styles from "./FooterNav.module.css"
import Loader from "../../../Ui/loader/Loader";

class FooterNav extends React.Component {

    state = {
        isLoading: false,
        items: [],
        marker: 0,
        serv_marker: '',
        rekls: [],
    }


    async componentDidMount() {
        let api_server = this.props.api_server
        let marker = this.props.marker
        let serv_marker = this.props.serv_marker
        let rekls = this.props.rekls
        let type = 'footer'
        let update = 0

        const xhr = new XMLHttpRequest();
        xhr.open('GET', api_server + 'key=11eb6dd2f9dcf57686be6f609d853f9c&type=' + type + '&update=' + update, true);
        xhr.send();
        this.setState({
            isLoading: true,
            marker: marker,
            serv_marker:serv_marker,
            rekls: rekls,
        })
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== 4) {
                return false
            }
            if (xhr.status !== 200) {
                console.log(xhr.status + ': ' + xhr.statusText)
            } else {
                let items = JSON.parse(xhr.responseText).records

                this.setState({
                    items: items,
                    isLoading: false,
                })
            }
        }
    }

    get_trs(rekl) {
        let rekls = this.state.rekls
        if (rekls[rekl] === undefined) {
            return -1
        } else {
            return rekls[rekl]
        }
    }

    get_links(cat) {
        let obj = this
        return (
            this.state.items.map(
                function (item, index) {
                    let trs = obj.get_trs(item.fields.REKL_ID)
                    if (item.fields.CATEGORY === cat && trs >= 0) {
                        let link = item.fields.LINK

                        if (obj.state.serv_marker.length > 0 && obj.state.marker !== obj.state.serv_marker) {
                            link = link.replace('XXX', obj.state.serv_marker).replace('YYY', trs)
                        } else {
                            link = link.replace('XXX', obj.state.marker).replace('YYY', trs)
                        }
                        if (trs === 0){
                            link = link.replace('&trs=0','')
                        }
                        return (
                            <li key={item.id}>
                                <a href={link} target="_blank" rel="noreferrer">
                                    {
                                        item.fields.REKL
                                    }
                                </a>
                            </li>
                        )
                    } else {
                        return null
                    }
                }
            )
        )
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div className={styles.nav}>
                    <Loader/>
                </div>
            )
        } else {
            return (
                <div className={styles.nav}>
                    <nav>
                        <div className={styles.nav__heading}>
                            Туры
                        </div>
                        <ul>
                            {
                                this.get_links('tours')
                            }
                        </ul>
                    </nav>
                    <nav>
                        <div className={styles.nav__heading}>
                            Отели
                        </div>
                        <ul>
                            {
                                this.get_links('hotels')
                            }
                        </ul>
                    </nav>
                    <nav>
                        <div className={styles.nav__heading}>
                            Экскурсии
                        </div>
                        <ul>
                            {
                                this.get_links('excursions')
                            }
                        </ul>
                    </nav>
                    <nav>
                        <div className={styles.nav__heading}>
                            Транспорт
                        </div>
                        <ul>
                            {
                                this.get_links('transport')
                            }
                        </ul>
                    </nav>
                </div>

            )
        }
    }
}

export default FooterNav